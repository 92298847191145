.com-discover-element---container {
	z-index: 82;
	position: fixed;
	right: 0;
	bottom: calc(30pt + 60pt + var(--safe-area---bottom));
	left: 0;
	width: 100vw;
	height: auto;
	padding: 0 var(--padding---global---page);
	> a,
	> div {
		box-shadow: var(--shadow---standard);
		border-radius: var(--border-radius---global);
	}
}