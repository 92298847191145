.com-poi-verified---container {
	position: relative;
	display: block;
	height: 100%;
	width: auto;
	max-width: 15pt;
	> img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center;
		opacity: 0.5;
	}
}

.com-poi-verified---container---popover {
	section {
		display: block;
		width: 100%;
		padding: var(--padding---global);
		> p {
			display: block;
			text-align: center;
			font-size: 10pt;
			opacity: 0.75;
		}
		> a {
			display: block;
			width: 100%;
			text-align: center;
			font-size: 10pt;
			opacity: 0.5;
		}
	}
}