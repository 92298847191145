.com-event-timeschedule---container {
	display: flex;
	justify-content: flex-start;
	background-color: var(--color---shadow);
	border-radius: 6pt;
	padding: 10pt;
	> div.com-event-timeschedule---is-now {
		width: 10pt;
		height: 10pt;
		background-color: #fd4755;
		border-radius: 35px;
		margin: 10pt 20pt 10pt 10pt;
		animation-name: com-event-timeschedule---recording-pulse;
		animation-duration: 1.5s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}
	> i {
		display: block;
		height: 30pt;
		line-height: 30pt;
		width: 30pt;
		padding: 0;
		text-align: center;
		opacity: 0.5;
	}
	> span {
		display: block;
		margin: 0 15pt 0 5pt;
		height: 30pt;
		width: 3pt;
		border-radius: 1pt;
		background-color: var(--color---light);
	}
	> section {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: calc(100% - 30pt - 3pt - 30pt);
		> p {
			display: block;
			height: 15pt;
			line-height: 15pt;
			font-size: 10pt;
			margin: 0;
			padding: 0;
			&:nth-child(1) {
				font-weight: bold;
			}
		}
	}
}

@keyframes com-event-timeschedule---recording-pulse {
	0% { box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, 0.3); }
	65% { box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0.3); }
	90% { box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0); }
}