//#region 																							c o n t a i n e r

	.md-discover-compass---container {
		position: fixed;
		z-index: 999999999;
		width: 100vw;
		height: 100vh;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		backdrop-filter: blur(6pt);
		background-color: rgba(255, 255, 255, 0.5);
		> .md-discover-compass---header {
			display: block;
			width: 100vw;
		}
	}

//#endregion

//#region 																							s e c o n d a r y

	.md-discover-compass---categories-subs {
		position: fixed;
		top: 60pt;
	//	height: 390pt;
		width: 100vw;
		height: calc(50vh + 27.5pt - var(--safe-area---bottom) - 5pt);
		overflow: scroll;
		padding: 6% var(--padding---global---modal);
		mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 6%, rgba(0, 0, 0, 1) 94%, rgba(0, 0, 0, 0) 100%);
		> section {
			display: flex;
			flex-direction: column;
			gap: 10pt;
			width: 100%;
			height: auto;
		}
	//	&:before {
	//		content: '{{spacer}}';
	//		display: block;
	//		position: absolute;
	//		z-index: 12389123;
	//		top: 0;
	//		right: 0;
	//		left: 0;
	//		width: 100%;
	//		height: 25pt;
	//		background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	//		color: transparent;
	//	}
	//	&:after {
	//		content: '{{spacer}}';
	//		display: block;
	//		position: absolute;
	//		z-index: 12389123;
	//		right: 0;
	//		bottom: 0;
	//		left: 0;
	//		width: 100%;
	//		height: 25pt;
	//		background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
	//		color: transparent;
	//	}
	}

//#endregion

//#region 																							p r i m a r y   /   w h e e l

	.md-discover-compass---compass {
		position: fixed;
		width: 600pt;
		height: 600pt;
		bottom: calc(-410pt + var(--safe-area---bottom));
		left: calc(50vw - 300pt);
		transition: 0.15s;
		> .md-discover-compass---compass---wheel {
			width: 600pt;
			height: 600pt;
			border-radius: 50%;
			position: relative;
			transition: 0.25s;
			background: radial-gradient(circle, var(--color---light) 0%, transparent 60%);
			> .md-discover-compass---compass---wheel-element {
				position: absolute;
				left: 300pt;
				top: 300pt;
				display: block;
				width: 0;
				height: 0;
				> div {
					&:nth-child(1) {
						position: relative;
						margin-top: calc(-300pt - 40pt);
						margin-left: calc(-40pt);
						width: 80pt;
						height: 80pt;
						border-radius: 50%;
						> img {
							display: block;
							width: 100%;
							height: 100%;
							object-fit: contain;
							transform: scale(0.5);
						}
						
					}
					&:nth-child(2) {
						margin-top: 10pt;
						margin-left: -50pt !important;
						width: 100pt !important;
						display: block;
						height: 15pt;
						line-height: 15pt;
						font-weight: bold;
						text-align: center;
					}
				}
			}
		}
		> .md-discover-compass---compass---arrow {
			position: fixed;
			z-index: 999009;
			bottom: var(--safe-area---bottom);
			width: 70pt;
			height: 250pt;
			padding-top: calc(250pt - 100pt);
	//		backdrop-filter: blur(3pt);
			background-color: transparent;
			text-align: center;
			> i {
				display: block;
				width: 70pt;
				height: 100pt;
				line-height: 100pt;
				font-size: 16pt;
				text-align: center;
				background-color: transparent;
			}
		}
		> .md-discover-compass---compass---close {
			position: fixed;
			right: 110pt;
			bottom: calc(30pt + var(--safe-area---bottom));
			left: 110pt;
			z-index: 999009;
			width: calc(100% - 220pt);
			height: auto;
			display: flex;
			justify-content: center;
			> i {
				display: block;
				background-color: var(--color---light);
				color: var(--color---dark);
				width: 40pt;
				height: 40pt;
				line-height: 40pt !important;
				padding: 0;
				text-align: center;
				font-size: 16pt;
				border-radius: 50%;
				margin: 0 15pt;
				transform: scale(1.5);
			}
		}
	}

//#endregion