.mapboxgl-control-container {
	display: none !important;
	visibility: hidden !important;
	z-index: -91283 !important;
	opacity: 0 !important;
	transform: translate(-1241234vh);
}

.mapboxgl-popup-content {
	border-radius: 6pt !important;
}

.mapboxgl-popup-close-button {
	display: none !important;
}