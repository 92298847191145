.md-entry-download-app---container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: var(--padding---global---modal);
	height: 90vh;
	gap: 25pt;
	text-align: center;
	> img {
		display: block;
		width: 100%;
		height: auto;
		transform: scale(0.55);
	}
	> h4 {
		display: block;
		font-weight: bold;
	}
	> div {
		display: block;
		> a {
			display: block;
			&:nth-child(1) {
				width: 80%;
				margin: 0 10%;
			}
			&:nth-child(2) {
				margin-top: 10pt;
			}
		}
	}
	> p {
		margin: 0;
	}
	> h6 {
		display: block;
		font-weight: bold;
		color: var(--color---folly);
	}
}