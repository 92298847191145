.sb-info-thanks---container {
	padding: var(--padding---global---page);
	> img {
		display: block;
		height: 70pt;
		width: 100%;
		margin-top: 30pt;
		object-fit: contain;
		object-position: center;
	}
	> section {
		display: block;
		> p {
			display: block;
			text-align: center;
			font-size: 10pt;
			font-weight: bold;
		}
	}
	> div {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 15pt;
		> a {
			width: 20vw;
			height: 40pt;
			> img {
				display: block;
				width: 20vw;
				height: 40pt;
				object-fit: contain;
			}
		}
	}
}