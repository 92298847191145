.com-button-share---container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 10pt;
	> div {
		display: flex;
		justify-content: center;
		gap: 10pt;
		background-color: var(--color---shadow);
		border-radius: 6pt;
		> i {
			display: block;
			height: 30pt;
			line-height: 30pt;
		}
		> span {
			display: block;
			padding-left: 10pt;
			height: 30pt;
			line-height: 30pt;
		}
	}
}