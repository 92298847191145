.com-gallery-placeholder---component {
	position: relative;
	display: block;
	border-radius: var(--border-radius---global);
//	background-color: var(--color---shadow);
	margin: 0;
	height: 200pt;
	overflow: hidden;
	> img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		background-color: transparent;
		&:nth-child(1) {
			z-index: 2;
			transform: scale(0.35);
			object-fit: contain;
		}
		&:nth-child(2) {
			z-index: 1;
			transform: scale(1.4);
			object-fit: cover;
			filter: blur(103px);
			background-blend-mode: color-dodge !important;
			opacity: 0.5;
		}
	}
}