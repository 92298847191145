.sb-poi-view-digitalmenu---container {
	display: block;
	padding: 10pt;
	> a {
		display: block;
		text-align: center;
		font-size: 10pt;
		opacity: 0.5;
		> span {
			font-weight: bold;
			font-size: 10pt;
		}
		> i {
			font-size: 10pt;
			padding-left: 10pt;
		}
	}
}

.sb-poi-view-digitalmenu---card {
	padding: 10pt 0;
	> .sb-poi-view-digitalmenu---card---title {
		display: flex;
		justify-content: center;
		width: 100% !important;
		> div {
			display: flex;
			justify-content: center;
			gap: 5pt;
			padding: 10pt;
			width: 100% !important;
			> img {
				display: block;
				width: auto;
				height: 40pt;
				line-height: 40pt;
				scale: 0.5;
			}
			> h6 {
				display: block;
				width: auto;
				height: 40pt;
				line-height: 40pt;
				margin: 0;
				padding: 0;
				font-size: 14pt;
			}
		}
	}
}