.com-poi-timetable---container {
	input {
		display: none;
		&:checked {
			+ .com-poi-timetable---table {
				display: block !important;
			}
		}
	}
	.com-poi-timetable---table {
		display: none;
	}
}