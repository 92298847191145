.sb-info-copyright---container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: calc(100vh - 100pt);
	padding: var(--padding---global---page);
	width: 100%;
	> .sb-info-copyright---container---motto-logo {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 15pt;
		padding: 25pt 0;
		> img {
			display: block;
			width: 100%;
			height: auto;
			transform: scale(0.6);
			margin-bottom: 15pt;
		}
		> h4 {
			display: block;
			width: 100%;
			height: auto;
			text-align: center;
			margin: 0;
			padding: 0;
		}
		> p {
			display: block;
			width: 100%;
			height: auto;
			text-align: center;
			margin: 0;
			padding: 0;
		}
	}
	> .com-copyright-component---container * {
		display: block;
	}
	> span {
		display: block;
		padding: 10pt;
		width: 100%;
		font-size: 9pt;
		text-align: center;
		opacity: 0.5;
	}
}