.com-spinner-refresher---component {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: auto;
	> i {
		position: relative;
		display: block;
		margin: 0 calc((100% - 30pt) / 2);
		width: 30pt;
		height: 30pt;
		line-height: 30pt;
		text-align: center;
		animation: com-spinner-refresher---component---rotate 1s linear infinite;
		opacity: 0.5;
	}
}

@keyframes com-spinner-refresher---component---rotate {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}