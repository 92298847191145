.com-map-preview---component {
	position: relative;
	display: block;
	width: 100%;
	height: 100pt;
	padding: 0 !important;
	overflow: hidden !important;
	* {
		pointer-events: none !important;
		touch-action: none !important;
	}
	> div {
		position: relative;
		display: block;
		width: 100%;
		margin-top: -50pt;
		height: 200pt;
		pointer-events: none !important;
		touch-action: none !important;
		> div {
			display: block;
			width: 100%;
			height: 200pt;
			pointer-events: none !important;
			touch-action: none !important;
			> div {
				display: block;
				width: 100%;
				height: 200pt;
			}
		}
	}
}