/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/

:root {

	--aspect-ratio--images: 11 / 16;
	--aspect-ratio--images---poi: 11 / 16;
	--aspect-ratio--tales: 9 / 16;

	--border-radius---button: 7pt;
	--border-radius---card: 7pt;
	--border-radius---global: 10pt;

	--color---light: #FFFFFF;
	--color---shadow: #F4F4F4;
	--color---folly: #FD4755;
	--color---greeny: #09BC89;
	--color---yelly: #FFCB22;
	--color---reddy: #eb445a;
	--color---blacky: #7e7e7e;
	--color---dark: #0D0D0D;
	--color---nopr: #263238;

	--dimension---top-bar: 50pt;

	--padding---global: 10pt;
	--padding---global---modal: 10pt;
	--padding---global---page: 10pt;

	--safe-area---top: env(safe-area-inset-top);
	--safe-area---bottom: env(safe-area-inset-bottom);

	--shadow---standard: 0pt 0pt 6pt 0.5pt rgba(0, 0, 0, 0.15);
	--shadow---inset: inset 0pt 0pt 6pt 0.5pt rgba(0, 0, 0, 0.15);

	/** primary **/

	--ion-color-primary: var(--color---folly);
	--ion-color-primary-rgb: var(--color---folly);
	--ion-color-primary-contrast: var(--color---light);
	--ion-color-primary-contrast-rgb: var(--color---folly);
	--ion-color-primary-shade: var(--color---folly);
	--ion-color-primary-tint: var(--color---folly);

	/** secondary **/

	--ion-color-secondary: #3dc2ff;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: var(--color---light);
	--ion-color-secondary-contrast-rgb: var(--color---folly);
	--ion-color-secondary-shade: #36abe0;
	--ion-color-secondary-tint: #50c8ff;

	/** tertiary **/

	--ion-color-tertiary: #5260ff;
	--ion-color-tertiary-rgb: 82, 96, 255;
	--ion-color-tertiary-contrast: var(--color---light);
	--ion-color-tertiary-contrast-rgb: var(--color---folly);
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	/** success **/

	--ion-color-success: var(--color---greeny);
	--ion-color-success-rgb: var(--color---greeny)1;
	--ion-color-success-contrast: var(--color---light);
	--ion-color-success-contrast-rgb: var(--color---light);
	--ion-color-success-shade: var(--color---greeny);
	--ion-color-success-tint: var(--color---greeny);

	/** warning **/

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: var(--color---dark);
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: var(--color---light);
	--ion-color-danger-contrast-rgb: var(--color---folly);
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/

	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: var(--color---light);
	--ion-color-dark-contrast-rgb: var(--color---folly);
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: var(--color---light);
	--ion-color-medium-contrast-rgb: var(--color---folly);
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: var(--color---dark);
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	/** nopr **/

	--ion-color-nopr: var(--color---nopr);
	--ion-color-nopr-rgb: var(--color---nopr);
	--ion-color-nopr-contrast: var(--color---light);
	--ion-color-nopr-contrast-rgb: var(--color---nopr);
	--ion-color-nopr-shade: var(--color---nopr);
	--ion-color-nopr-tint: var(--color---nopr);


}

//	C U S T O M   C O L O R S

.ion-color-nopr {
	--ion-color-base: var(--ion-color-nopr);
	--ion-color-base-rgb: var(--ion-color-nopr-rgb);
	--ion-color-contrast: var(--ion-color-nopr-contrast);
	--ion-color-contrast-rgb: var(--ion-color-nopr-contrast-rgb);
	--ion-color-shade: var(--ion-color-nopr-shade);
	--ion-color-tint: var(--ion-color-nopr-tint);
}