.md-discover-search---header {
	position: sticky !important;
	top: 0 !important;
	display: block;
	padding: 0 var(--padding---global---modal) var(--padding---global---modal) var(--padding---global---modal);
	> label.md-discover-search---search-bar {
		display: block;
		background-color: var(--color---light);
		border-radius: 15pt;
		width: 100%;
		height: 30pt;
		line-height: 30pt;
		padding: 0;
		box-shadow: var(--shadow---standard);
		> i {
			width: 30pt;
			height: 30pt;
			text-align: center;
			opacity: 0.5;
		}
		> input {
			width: calc(100% - 30pt);
			height: 30pt;
			line-height: 30pt;
			background-color: transparent !important;
		}
	}
	> .md-discover-search---search-selector {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		gap: 10pt;
		font-weight: bold;
		> span {
			opacity: 1;
		}
		> input {
			display: none !important;
			&:checked {
				+ label {
					opacity: 1 !important;
					color: var(--color---folly);
					text-decoration: underline !important;
				}
			}
		}
		> label {
			opacity: 0.5;
			transition: 0.15s;
		}
	}
}

.md-discover-search---container {
	display: block;
	padding: 0 var(--padding---global---modal) var(--padding---global---modal) var(--padding---global---modal);
}
