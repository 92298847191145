.com-gallery-slider---component {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: var(--padding---global---page) 0;
	.swiper-slide {
		display: block;
		padding: 0 var(--padding---global---page);
		img {
			display: block;
			width: 100%;
			height: 100%;
			aspect-ratio: var(--aspect-ratio---poi);
			object-fit: cover;
			background-color: var(--color---shadow);
			border-radius: var(--border-radius---card);
		}
	}
}

.swiper-pagination-bullet {
	background-color: var(--color---light) !important;
}

.swiper-pagination-bullet-active {
	background-color: var(--color---light) !important;
}