.com-user-verified---container {
	position: relative;
	display: block;
	width: auto;
	max-width: 15pt;
	> img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center;
	}
}