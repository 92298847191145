.sb-info-stats---container {
	padding: var(--padding---global---page);
	> img {
		display: block;
		height: 40pt;
		width: 100%;
		margin-top: 30pt;
		object-fit: contain;
		object-position: center;
	}
}