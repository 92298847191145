.com-markdown---container {
	position: relative;
	padding: 10pt;
	font-size: 10pt;
	h6 {
		font-weight: bold;
		text-align: center;
		opacity: 0.5;
	}
	h3 {
		position: sticky;
		top: -20pt;
		display: block;
		margin: -20pt 0;
		width: 100%;
		height: 75pt;
		font-size: 16pt;
		line-height: 75pt;
		padding: 10pt;
		background-color: var(--color---light);
		color: var(--color-primary);
		font-weight: bold;
		text-align: center;
	}
	h4 {
		position: sticky;
		top: calc(-20pt + 75pt);
		display: block;
		margin: 20pt 0;
		width: 100%;
		height: auto;
		font-size: 12pt;
		line-height: 12pt;
		padding: 10pt;
		background-color: var(--color---light);
		color: var(--color-primary);
		font-weight: bold;
	}
	ol, ul {
		list-style: decimal !important;
		width: calc(100% - 25pt);
		margin-left: 25pt;
	}
	li {
		display: list-item;
		margin-bottom: 10pt;
		
	}
	p {
		text-align: justify;
		padding: 10pt;
	}
}