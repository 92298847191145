.com-text-component---container {
	position: relative;
	display: block;
	width: 100%;
	height: auto;
	> p {
		display: block;
		margin: 0;
		width: 100%;
		height: auto;
		white-space: break-spaces;
	}
}