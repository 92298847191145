.com-poi-timetable-table---container {
	display: block;
	> div {
		display: flex;
		justify-content: space-between;
		gap: 10pt;
		padding: 10pt 20pt 0 20pt;
		> div {
			&:nth-child(1) {
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 30%;
				> p {
					display: block;
					margin: 0;
					padding: 0;
					font-weight: bold;
				}
			}
			&:nth-child(2) {
				display: inline-block;
				width: 70%;
				> div {
					display: inline-block;
					width: auto;
					&:not(:last-child) {
						&:after {
							content: '•';
							display: inline-block;
							padding: 0 10pt;
						}
					}
				}
			}
		}
		&:last-child {
			padding: 10pt 20pt;
		}
	}
}