//#region 																							L A R G E

	.com-poi-component---large {
		display: block;
		width: 100%;
		height: auto;
		> div {
			display: block;
			width: 100%;
			height: 150pt;
			background-color: var(--color---shadow);
			object-fit: cover;
			border-radius: 6pt;
			> img {
				display: block;
				object-fit: contain;
				transform: scale(0.55);
				width: 100%;
				height: 100%;
				border-radius: 6pt;
			}
		}
		> img {
			display: block;
			width: 100%;
			height: 200pt;
			background-color: var(--color---shadow);
			object-fit: cover;
			border-radius: 6pt;
		}
		> h5 {
			display: block;
			padding: 20pt 0;
			margin: 0;
			height: auto;
			font-size: 16pt;
			font-weight: bold;
			text-align: center;
			> .com-poi-verified---container {
				display: inline-block;
				width: 12.5pt;
				margin-left: 7.5pt;
				height: inherit !important;
			}
		}
	}

//#endregion

//#region 																							N O R M A L

	.com-poi-component---medium {
		display: flex;
		width: 100%;
		height: auto;
		gap: 10pt;
		padding: 10pt;
		> img {
			width: 60pt;
			height: 60pt;
			background-color: var(--color---shadow);
			border-radius: 6pt;
			object-fit: cover;
		}
		> div {
			position: relative;
			display: block;
			width: 60pt;
			height: 60pt;
		//	background-color: var(--color---shadow);
			border-radius: var(--border-radius---global);
			overflow: hidden;
			> img {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				display: block;
				width: 100%;
				height: 100%;
				background-color: transparent;
				&:nth-child(1) {
					z-index: 2;
					transform: scale(0.35);
					object-fit: contain;
				}
				&:nth-child(2) {
					z-index: 1;
					transform: scale(1.24);
					object-fit: cover;
					filter: blur(83px);
					background-blend-mode: color-dodge !important;
					opacity: 0.75;
				}
			}
		}
		> section {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: calc(100% - 60pt - 10pt);
			height: auto;
			gap: 5pt;
			> .chip---verified {
				display: block;
				font-weight: bold;
				color: var(--color-greeny);
				border-radius: 6pt;
				font-size: 8pt;
				> i {
					width: 10pt;
					margin-right: 5pt;
				}
			}
			> h5 {
				display: block;
				padding: 0;
				margin: 0;
				height: auto;
				width: auto;
				> .com-poi-verified---container {
					display: inline-block;
					width: 10pt;
					margin-left: 5pt;
					height: inherit !important;
				}
			}
			> p {
				display: block;
				width: 100%;
				height: auto;
				padding: 0;
				margin: 0;
				font-size: 10pt;
				font-weight: bold;
				line-height: 12pt;
				color: var(--color---blacky);
				opacity: 0.5;
				> i {
					margin-right: 5pt;
					transform: scale(0.75);
				}
			}
		}
	}

//#endregion

//#region 																							S M A L L 

	.com-poi-component---small {
		width: 100%;
		font-weight: bold;
		img {
			width: 30pt;
			height: 30pt;
			&:not(.is-component-poi-svg-icon) {
				object-fit: cover !important;
			}
		}
		.is-ion-item-button {
			> div {
				> img {
					width: 20pt;
					margin-right: 10pt;
					object-fit: contain !important;
					transform: scale(0.75);
				}
			}
		}
	}

//#endregion