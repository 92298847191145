.md-entry-language---container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: var(--padding---global---modal);
	height: 90vh;
	ion-card {
		margin-bottom: 10pt !important;
	}
}

.md-entry-language---header {
	display: block;
	> img {
		display: block;
		height: 100pt;
		width: 100%;
		transform: scale(0.55);
	}
	> h4 {
		display: block;
		width: 100%;
		height: 50pt;
		line-height: 50pt;
		text-align: center;
		animation-name: md-entry-language---header---anim;
		animation-duration: 4000ms;
	//	animation-timing-function: ease-in-out;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		opacity: 0;
		transition: 0.15s;
	}
}

@keyframes md-entry-language---header---anim {
	0% { opacity: 0; }
	35% { opacity: 1; }
	65% { opacity: 1; }
	100% { opacity: 0; }
}