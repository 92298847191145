.md-media-view---container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: #0d0d0d;
	> img {
		&:nth-child(1) {
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: center;
			background: none;
		}
		&:nth-child(2) {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			background: none;
			opacity: 0.25;
			filter: blur(10pt);
		}
	}
}

.md-media-view---close {
	position: fixed;
	bottom: calc(4.5vh + env(safe-area-inset-bottom));
	display: block;
	width: 100%;
	height: 45pt;
	border-radius: 50%;
	text-align: center;
	> i {
		width: 100%;
		height: 45pt;
		line-height: 45pt;
	//	color: #09bc8a;
		color: white;
		text-align: center;
		font-size: 20pt;
	}
}