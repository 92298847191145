//#region 																							O V E R R I D E S








						//					* { outline: 1px solid orange !important; }








//#endregion

//#region 																							O V E R R I D E S

	* {
		box-sizing: border-box !important;
	}

	*:not(i) {
		font-family: 'Quicksand', sans-serif !important;
		font-style: normal;
		font-optical-sizing: auto;
	}

	::-webkit-scrollbar,
	*::-webkit-scrollbar {
		-webkit-appearance: none !important;
		display: none !important;
		opacity: 0 !important;
		width: 0pt !important;
		visibility: hidden !important;
		transform: translate(-829vw) !important;
	}

	.scroll-bar-indicator {
		display: none !important;;
		opacity: 0 !important;
	}

	.no-shadow {
		box-shadow: none !important;
	}

	ion-list-header {
		ion-label {
			padding: 0 !important;
			i {
				display: inline-block;
				width: 30pt;
				height: 30pt !important;
				line-height: 30pt !important;
				color: var(--color---folly);
			}
			span {
				
			}
		}
	}

	ion-card {
		margin: 0 !important;
		ion-list {
			p {
				padding: 0 10pt;
			}
		}
		&.this-card-has-margin {
			margin: 0 10pt !important;
		}
	}

	a,
	a * {
		text-transform: unset;
		text-decoration: none;
		color: unset;
	}

	ion-content {
	//	--offset-bottom: auto !important;
	//	--overflow: hidden;
	//	overflow: auto;
		background-color: var(--color---light);
		&::-webkit-scrollbar {
			display: none;
		}
	}

	ion-toolbar {
		--background: var(--color---light) !important;
		--border-color: var(--color---light) !important;
	}

	ion-footer {
		background-color: var(--color---light);
		padding-bottom: env(safe-area-inset-bottom);
	}
	
	ion-header, 
	.header-md {
		box-shadow: none !important;
	}

	ion-header {
	//	padding-top: env(safe-area-inset-top);
	}

	i {
		&.fa-facebook { color: #1877f2 !important; }
		&.fa-instagram { color: #833ab4 !important; }
		&.fa-youtube { color: #ff0000 !important; }
		&.fa-tiktok { color: #000000 !important; }
		&.fa-twitter { color: #1da1f2 !important; }
		&.fa-linkedin { color: #0e76a8 !important; }
	}

	ion-segment {
		--background: var(--color---shadow) !important;
		ion-segment-button {
			--padding-top: 5pt !important;
			--padding-bottom: 5pt !important;
			font-size: 12pt !important;
			img {
				display: block;
				height: 14pt;
				width: 14pt;
				object-fit: contain;
			}
		}
	}

//#endregion

//#region 																							I N P U T S

	input {
		height: 30pt;
		line-height: 30pt;
		border: none;
	}

	.input---nb-label {
		display: block;
		width: 100%;
		height: 10pt;
		line-height: 10pt;
		font-size: 10pt;
		font-weight: bold;
		opacity: 0.5;
		text-transform: lowercase;
		margin-bottom: 5pt;
		letter-spacing: 0.25pt;
		margin: 10pt 0;
		z-index: 1;
		&.is-required {
			&::after {
				content: 'richiesto';
				color: var(--color---folly);
				padding-left: 10pt;
			}
		}
		> i {
			padding-right: 7.5pt;
		}
	}

	.input---nb-outlined {
		display: block;
		width: 100%;
		font-size: 12pt;
		line-height: 18pt;
		height: auto;
		min-height: auto;
		max-height: auto;
		border-radius: 6pt;
		background-color: unset;
	//	border: 1pt solid var(--color---blacky);
		border: none !important;
		box-shadow: var(--shadow---inset) !important;
		padding: 10pt;
		resize: none;
	}

	.input---nb-outlined---error {
		outline: 2pt solid var(--color---folly) !important;
	}

	textarea {
		resize: none !important;
	}

	.input---nb-outlined---rich-textarea {
		position: relative;
		display: block;
		width: 100%;
		min-height: 100pt;
		overflow-y: scroll !important;
		height: auto;
		border-radius: 6pt;
		background-color: unset;
	//	border: 1pt solid var(--color---blacky);
		border: none !important;
		box-shadow: var(--shadow---inset) !important;
		> div, 
		> textarea {
			position: absolute;
			top: 10pt;
			right: 10pt;
			bottom: 10pt;
			left: 10pt;
			display: block;
			width: calc(100% - 20pt);
			height: auto !important;
			font-size: 12pt;
			line-height: 18pt;
			padding: 0;
			background-color: transparent;
			border: none !important;
			white-space: pre-wrap !important;
			word-wrap: break-word !important;
			overflow: hidden;
			appearance: none !important;
		}
		div {
			pointer-events: none !important;
			z-index: 4;
			width: calc(100% - 20pt);
			height: auto !important;
			background-color: transparent;
			> span {
				&.input---nb-outlined---rich-textarea---hashtag { color: var(--color---folly); }
				&.input---nb-outlined---rich-textarea---url { color: var(--color---folly); }
				&.input---nb-outlined---rich-textarea---usertag { color: var(--color---folly); }
			}
		}
		textarea {
			z-index: 3;
			resize: none;
			color: transparent !important;
			height: calc(100% - 20pt) !important;
			caret-color: var(--color---dark) !important;
		}
	}

	input:focus,
	input:active,
	textarea:focus,
	textarea:active,
	select:focus,
	select:active {
		outline: none !important;
	}

//#endregion

//#region 																							M O D A L S






//#endregion

//#region 																							C O N T A I N E R

	.sb-page---container {
		position: relative;
		display: block;
		width: 100vw;
		height: auto;
		min-height: 90vh;
		padding: var(--padding---global---page);
		background-color: var(--color---light);
		&.ignore-limits {
			padding: 0 !important;
		}
	}

	.md-modal---container {
		position: relative;
		display: block;
		width: 100vw;
		height: auto;
		padding: var(--padding---global---modal);
		background-color: var(--color---light);
		&.ignore-limits {
			padding: 0 !important;
		}
		&.is-swipable {
			padding-top: 45pt !important;
		}
	}

	.container---footer-section {
		display: flex;
		gap: 10pt;
		width: 100%;
		height: 60pt;
		padding: var(--padding---global);
		* {
			font-size: 14pt;
			line-height: 40pt;
			margin: 0;
		}
	}

	.container---footer-section---text-input {
		position: relative;
		display: flex;
		gap: 10pt;
		width: 100%;
		height: auto;
		padding: var(--padding---global);
		> textarea {
			transition: 0.15s;
			padding-right: calc(5pt + 5pt + 25pt) !important;
			height: 35pt;
			line-height: 15pt;
			font-size: 11pt;
			&:focus {
				height: 100pt;
			}
		}
		> i {
			position: absolute;
			right: calc(5pt + var(--padding---global));
			bottom: calc(5pt + var(--padding---global));
			border-radius: var(--border-radius---button);
			background-color: var(--color---folly);
			color: var(--color---light);
			width: 25pt;
			height: 25pt;
			line-height: 25pt;
			text-align: center;
		}
	}

//#endregion

//#region 																							E L E M E N T S

	.is-ion-item-button {
		> i {
			color: var(--color-blacky);
			width: 20pt;
			text-align: center;
			margin-right: 10pt;
			opacity: 0.5;
		}
		> img {
			width: 20pt;
			margin-right: 10pt;
			object-fit: contain;
			transform: scale(0.75);
		}
	}

	.is-element {
		display: block;
		background-color: var(--color---light);
		box-shadow: var(--shadow---standard);
		&.is-card {
			border-radius: var(--border-radius---card);
			box-shadow: var(--shadow---standard);
			padding: var(--padding---global);
			overflow: hidden;
			&.no-padding {
				padding: 0 !important;
			}
		}
		&.is-button {
			display: flex;
			justify-content: center;
			gap: 10pt;
			min-width: 40pt;
			line-height: 40pt;
			background-color: var(--color---shadow);
			color: var(--color---dark);
			border-radius: var(--border-radius---button);
			font-size: 20pt;
			> i {
				display: block;
				width: auto;
				height: 40pt;
				line-height: 40pt;
				text-align: center;
			}
			> h4 {
				display: block;
				width: auto;
				height: 40pt;
				line-height: 40pt;
			}
			&.is-full-width {
				width: 100%;
			}
		}
		&.is-color---folly {
			background-color: var(--color---folly);
			color: var(--color---light);
			* { color: var(--color---light); }
		}
		&.is-color---shadow {
			background-color: var(--color---shadow);
			color: var(--color---dark);
			* { color: var(--color---dark); opacity: 0.5;}
		}
		&.is-color---success {
			background-color: var(--color---greeny);
			color: var(--color---light);
			* { color: var(--color---light); }
		}
		&.is-color---warning {
			background-color: var(--color---yelly);
			color: var(--color---light);
			* { color: var(--color---light); }
		}
		&.is-color---danger {
			background-color: var(--color---reddy);
			color: var(--color---light);
			* { color: var(--color---light); }
		}
		&.is-color---transparent {
			background-color: transparent;
			color: var(--color---dark);
			* { color: var(--color---dark); }
		}
	}

	.element---is-popover-container {
		section {
			display: block;
			width: 100%;
			padding: var(--padding---global);
			> p {
				display: block;
				text-align: center;
				font-size: 10pt;
				opacity: 0.75;
			}
			> a {
				display: block;
				width: 100%;
				text-align: center;
				font-size: 10pt;
				opacity: 0.5;
			}
		}
	}

//#endregion








//								* 										{ outline: 1pt solid orange !important; }
