.md-discover-search-preview---opacizer {
	position: fixed;
	z-index: 44;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	width: 100vw;
	height: 100vh;
}

.md-discover-search-preview---container {
	position: fixed;
	z-index: 9123;
	top: calc(70pt + 50pt);
	right: var(--padding---global---page);
	left: var(--padding---global---page);
	display: block;
	padding: var(--padding---global---modal);
	border-radius: 15pt;
	> section {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		gap: 10pt;
		> a,
		> span {
			display: flex;
			width: auto;
			border-radius: 3pt;
			background-color: var(--color---shadow);
			padding: 0 10pt 0 0;
			overflow: hidden;
			text-overflow: ellipsis;
			> i {
				display: block;
				width: 30pt;
				height: 30pt;
				line-height: 30pt;
				font-size: 10pt;
				margin-right: 5pt;
				text-align: center;
			}
			> img {
				display: block;
				width: 30pt;
				height: 30pt;
				margin-right: 5pt;
				transform: scale(0.5);
			}
			> span {
				display: block;
				height: 30pt;
				line-height: 30pt;
				overflow: hidden;
				text-overflow: ellipsis;
				font-weight: bold;
				&:nth-child(1) {
					padding-left: 10pt;
				}
			}
		}	
		> div {
			display: flex;
			width: auto;
			padding: 0 10pt 0 0;
			overflow: hidden;
			text-overflow: ellipsis;
			> span {
				display: block;
				height: 30pt;
				line-height: 30pt;
				overflow: hidden;
				text-overflow: ellipsis;
				font-weight: bold;
				color: var(--color---folly);
			}
		}
	}
}