.com-user-component---container {
	display: flex;
	width: 100%;
	height: auto;
	gap: 10pt;
	padding: 10pt;
	> img {
		width: 35pt;
		height: 35pt;
		background-color: var(--color---shadow);
		border-radius: 50%;
		object-fit: cover;
	}
	> section {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: calc(100% - 35pt - 10pt);
		height: auto;
		gap: 5pt;
		> h5 {
			display: block;
			padding: 0;
			margin: 0;
			height: auto;
			width: auto;
			> .com-user-verified---container {
				display: inline-block;
				width: 10pt;
				margin-left: 5pt;
				height: inherit !important;
			}
		}
	}
}