.com-poi-emergency---component {
	display: block;
	width: 100%;
	height: auto;
	padding: 10pt;
	> h6 {
		font-size: 12pt;
		text-align: center;
		padding: 0;
	}
	> h4 {
		font-size: 16pt;
		text-align: center;
		padding: 0;
	}

	
	


	
}