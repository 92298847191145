.sb-info-presentation---container {
	display: block;
	padding: var(--padding---global---page);
	> section {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 15pt;
		padding: 25pt 0;
		> img {
			display: block;
			width: 100%;
			height: auto;
			transform: scale(0.6);
			margin-bottom: 15pt;
		}
		> h4 {
			display: block;
			width: 100%;
			height: auto;
			text-align: center;
			margin: 0;
			padding: 0;
		}
		> p {
			display: block;
			width: 100%;
			height: auto;
			text-align: center;
			margin: 0;
			padding: 0;
		}
	}
}