.com-infinite-scroller-component---container {
	display: block;
	width: 100%;
	height: 100pt;
	> p {
		display: block;
		width: 100%;
		height: 100pt;
		line-height: 100pt;
		margin: 0;
		padding: 0;
		text-align: center;
		font-size: 10pt;
	}
}