.com-spinner-tag---component {
	position: relative;
	display: block;
	text-align: center;
	animation: com-spinner-tag---component---rotate 1s linear infinite;
	opacity: 0.5;
}

@keyframes com-spinner-tag---component---rotate {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}