.com-404---container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 10pt 10pt 100pt 10pt;
	> img {
		display: block;
		width: 100%;
		height: 100vw;
		transform: scale(0.75);
		opacity: 0.75;
	}
	> h3 {
		text-align: center;
		font-weight: bold;
		opacity: 0.75;
		padding: 5pt 10pt;
	}
	> p {
		text-align: center;
		padding: 5pt 10pt;
		opacity: 0.5;
		font-weight: bold;
	}
	> span {
		text-align: center;
		padding: 5pt 10pt;
		opacity: 0.5;
	}
	&.com-404---container---is-dark {
		filter: invert(1) !important;
	}
}