.sb-discover-map---header {
	position: absolute;
	display: block;
	z-index: 45;
	top: 0;
	right: 0;
	left: 0;
	display: block;
	width: 100vw;
	height: auto;
	> div {
		display: flex;
		justify-content: space-between;
		gap: 10pt;
		padding: 10pt;
		> div {
			position: relative;
			display: block;
			background-color: var(--color---light);
			border-radius: 15pt;
			width: 30pt;
			height: 30pt;
			line-height: 30pt;
			text-align: center;
			box-shadow: var(--shadow---standard);
			> i {
				width: 30pt;
				height: 30pt;
				line-height: 30pt;
			}
		}
		> label {
			display: block;
			background-color: var(--color---light);
			border-radius: 15pt;
			width: calc(100% - ((30pt - 10pt) * 2));
			height: 30pt;
			line-height: 30pt;
			padding: 0;
			box-shadow: var(--shadow---standard);
			> i {
				width: 30pt;
				height: 30pt;
				text-align: center;
				opacity: 0.5;
			}
			> input {
				width: calc(100% - 30pt);
				height: 30pt;
				line-height: 30pt;
				background-color: transparent !important;
			}
		}
	}
	> section {
		&.sb-discover-map---header---loader {
			position: absolute;
			z-index: 48;
			right: 0;
			bottom: -40pt;
			display: block;
			width: 50pt;
			height: 40pt;
			> i {
				font-size: 12pt;
				line-height: 40pt;
			}
		}
	}
}

.sb-discover-map---container-loader {
	position: fixed;
	z-index: 2;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	flex-direction: column;
	background-color: var(--color---light);
}

.sb-discover-map---container {
	display: block;
	width: 100vw;
	height: 100vh;
	> .sb-discover-map---map {
		display: block;
		width: 100vw;
		height: 100vh;
//		background-color: rebeccapurple;
		.mapboxgl-map {
			display: block;
			position: fixed;
			z-index: 1;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
		}
	}
}

.sb-discover-map---me---marker {
	display: block;
	background-color: var(--color---folly);
	width: 15pt;
	height: 15pt;
	border-radius: 50%;
	border: 1pt solid var(--color---dark);
}

.sb-discover-map---me---popup {
	display: block;
	width: auto;
	height: 15pt;
	font-size: 12pt;
	padding: 0 10pt;
}

.sb-discover-map---me---tale-marker {
	display: block;
	width: 40pt;
	height: 40pt;
	background-color: var(--color---light);
	background-size: cover;
	background-position: center;
	border-radius: 50%;
	border: 2pt solid var(--color---light);
}

.sb-discover-map---navigation---secondary {
	position: fixed;
	right: 0;
	bottom: calc(30pt + 60pt + var(--safe-area---bottom));
	left: 0;
	z-index: 43;
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	> a,
	> div {
		position: relative;
		display: block;
		color: var(--color---dark);
		width: 40pt;
		height: 40pt;
		border-radius: 50%;
		margin: 0 12.5pt;
		> i {
			display: block;
			width: 40pt;
			height: 40pt;
			line-height: 40pt;
			text-align: center;
			font-size: 14pt;
		}
		> .sb-discover-map---notifications {
			position: absolute;
			top: -5pt;
			right: -5pt;
			z-index: 99;
			display: block;
			width: 20pt;
			height: 20pt;
			line-height: 20pt;
			border-radius: 50%;
			font-size: 10pt;
			font-weight: bold;
			text-align: center;
			background-color: var(--color---folly);
			color: var(--color---light);
			box-shadow: var(--shadow---standard);
		}
	}
}

.sb-discover-map---navigation---main {
	position: fixed;
	right: 0;
	bottom: calc(30pt + var(--safe-area---bottom));
	left: 0;
	z-index: 43;
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	> a,
	> div {
		position: relative;
		display: block;
		color: var(--color---dark);
		width: 40pt;
		height: 40pt;
		border-radius: 50%;
		margin: 0 15pt;
		box-shadow: var(--shadow---standard);
		> i {
			display: block;
			width: 40pt;
			height: 40pt;
			line-height: 40pt;
			text-align: center;
			font-size: 14pt;
		}
		&.sb-discover-map---navigation---main---center {
			transform: scale(1.5);
		}
	}
}