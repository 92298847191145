.sb-poi-view---container {
	display: block;
	padding-bottom: 20pt;
	> * {
		margin-bottom: 20pt;
	}
	> .sb-poi-view---image {
		display: block;
		width: 100%;
		min-height: 120pt;
		height: auto;
		overflow: hidden;
		.com-gallery-slider---component {
			margin: 0 -10pt;
		}
	}
	> h6 {
		display: flex;
		justify-content: space-between;
		color: var(--color---dark);
		padding: 0;
		margin: 20pt 0 10pt 0;
		> a {
			font-weight: bold;
			color: var(--color---folly);
		}
		> span {
			font-weight: bold;
			opacity: 0.5;
		}
	}
	> h3 {
		padding: 10pt 0 !important;
		margin: 10pt 0;
		> span {
			font-size: inherit;
		}
		> .com-poi-verified---container {
			display: inline-block;
			width: 12.5pt;
			margin-left: 10pt;
			height: inherit !important;
		}
	}
	/*
		> .sb-poi-view---container---updates {
			> a {
				> h6 {
					width: 100%;
					text-align: center;
					height: 20pt;
					line-height: 20pt;
					font-size: 10pt;
				}
			}
		}
	*/
	> .sb-poi-view---container---view-content {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		gap: 10pt;
		> a {
			display: flex;
			justify-content: space-between;
			gap: 5pt;
			height: 20pt;
			border: 1pt solid var(--color---folly);
			border-radius: 10pt;
			padding: 0 10pt;
			> i {
				font-size: 10pt;
				height: 20pt;
				line-height: 20pt;
				color: var(--color---folly);
			}
			> span {
				color: var(--color---folly);
				font-size: 10pt;
				font-weight: bold;
				height: 20pt;
				line-height: 20pt;
			}
		}
	}
	> .sb-poi-view---container---contacts {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 15pt;
		height: auto;
		padding: 10pt 0;
		> section {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 15pt;
			height: auto;
			> a {
				display: flex;
				justify-content: space-between;
				gap: 10pt;
				> i {
					display: block;
					height: 14pt;
					line-height: 14pt;
					font-size: 14pt;
					&.fa-phone { color: var(--color---dark); opacity: 0.5; }
					&.fa-envelope { color: var(--color---dark); opacity: 0.5; }
					&.fa-globe { color: var(--color---dark); opacity: 0.5; }
					&.fa-facebook { color: #1877f2; }
					&.fa-instagram { color: #833ab4; }
					&.fa-youtube { color: #ff0000; }
					&.fa-tiktok { color: #000000; }
					&.fa-twitter { color: #1da1f2; }
				}
				> span {
					height: 14pt;
					line-height: 14pt;
					font-size: 12pt;
				}
			}
		}
	}
	> .sb-poi-view---attributes {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 5pt;
		padding-bottom: 10pt;
		> div {
			display: flex;
			justify-content: space-between;
			gap: 5pt;
			height: 20pt;
			border-radius: 6pt;
			border: 1pt solid var(--color---shadow);
			padding: 0 10pt !important;
			> img {
				display: block;
				height: 18pt;
				width: 18pt;
				transform: scale(0.65);
			}
			> span {
				color: var(--color---dark);
				font-size: 10pt;
				font-weight: bold;
				height: 18pt;
				line-height: 18pt;
			}
		}
	}
}