//#region 																							L A R G E

	.com-event-component---large {
		display: block;
		width: 100%;
		height: auto;
		> div {
			display: block;
			width: 100%;
			height: 150pt;
			background-color: var(--color---shadow);
			object-fit: cover;
			border-radius: 6pt;
			> img {
				display: block;
				object-fit: contain;
				transform: scale(0.55);
				width: 100%;
				height: 100%;
				border-radius: 6pt;
			}
		}
		> img {
			display: block;
			width: 100%;
			height: 100%;
			background-color: var(--color---shadow);
			object-fit: cover;
			border-radius: 6pt;
		}
		> h5 {
			display: block;
			padding: 20pt 0 10pt 0;
			margin: 0;
			height: auto;
			font-size: 16pt;
			font-weight: bold;
			text-align: center;
		}
		> p {
			display: block;
			padding: 0 0 20pt 0;
			font-size: 12pt;
			font-weight: bold;
			text-align: center;
			opacity: 0.5;
		}
	}

//#endregion

//#region 																							M E D I U M

	.com-event-component---medium {
		position: relative;
		display: flex;
		width: 100%;
		height: auto;
		gap: 10pt;
		padding: 10pt;
		> img {
			width: 60pt;
			aspect-ratio: 9 / 12;
			background-color: var(--color---shadow);
			border-radius: 6pt;
			object-fit: cover;
		}
		> section {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: calc(100% - 60pt - 10pt);
			height: auto;
			gap: 5pt;
			> h5 {
				display: block;
				padding: 0;
				margin: 0;
				height: auto;
				width: auto;
			}
			> p {
				display: block;
				width: 100%;
				height: auto;
				padding: 0;
				margin: 0;
				font-size: 10pt;
				font-weight: bold;
				line-height: 12pt;
				color: var(--color---blacky);
				opacity: 0.5;
				> i {
					margin-right: 5pt;
					transform: scale(0.75);
				}
				&.com-event---is-now {
					margin-top: 5pt;
					opacity: 1 !important;
					> span {
						color: var(--color---light);
						background-color: var(--color---folly);
						width: auto;
						padding: 5pt 10pt;
						border-radius: 6pt;
					}
				}
			}
		}
		&.com-event---is-finished {
			> * {
				opacity: 0.45;
			}
			> img {
				opacity: 0.45;
				filter: grayscale(1);
			}
		}
	}

//#endregion