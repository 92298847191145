.sb-info-digitalmenu-allergens---container {
	display: block;
	padding: var(--padding---global---page);
	width: 100%;
}


.sb-info-digitalmenu-allergens---card {
	padding: 10pt 0;
	> .sb-info-digitalmenu-allergens---card---title {
		display: flex;
		justify-content: center;
		width: 100% !important;
		> div {
			display: flex;
			justify-content: center;
			gap: 5pt;
			padding: 10pt;
			width: 100% !important;
			> img {
				display: block;
				width: auto;
				height: 40pt;
				line-height: 40pt;
				scale: 0.5;
			}
			> h6 {
				display: block;
				width: auto;
				height: 40pt;
				line-height: 40pt;
				margin: 0;
				padding: 0;
				font-size: 14pt;
			}
		}
	}
	> p {
		text-align: center;
		padding: 10pt;
	}
}