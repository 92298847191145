.com-header-logo---component {
	display: block;
	display: flex;
	justify-content: center;
	padding: 10pt;
	width: 100vw;
	height: 70pt;
	padding-top: var(--ion-safe-area-bottom);
	> img {
		display: block;
		width: 100vw;
		height: 100%;
	//	height: var(--dimension---top-bar);
		max-height: 75pt;
		transform: scale(0.65);
		object-fit: contain;
	}
	&.com-header-logo---hidden {
		display: hidden !important;
		opacity: 0 !important;
	}
}