.md-entry-cookies---container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: var(--padding---global---modal);
	height: 90vh;
	gap: 25pt;
	text-align: center;
	> img {
		display: block;
		width: 100%;
		height: auto;
		transform: scale(0.55);
	}
	> h4 {
		display: block;
		font-weight: bold;
	}
	> div {
		display: flex;
		flex-direction: column;
		font-size: 10pt;
		width: 100%;
		gap: 10pt;
		> span {
			display: block;
			&:nth-of-type(1) {
				font-weight: bold;
			}
			&:last-child {
				color: var(--color---folly);
				font-weight: bold;
			}
		}
	}
}