.sb-event-view---container {
	display: block;
	padding-bottom: 20pt;
	> * {
		margin-bottom: 20pt;
	}
	> .sb-event-view---poster {
		display: block;
		width: 100vw;
		margin: 0 calc(var(--padding---global---page) * -1) 0 calc(var(--padding---global---page) * -1);
		padding: 0 var(--padding---global---page);
		min-height: 120pt;
		height: auto;
		overflow: hidden;
		> img {
			border-radius: var(--border-radius---global) !important;
		}
	}
	> h6 {
		display: flex;
		justify-content: space-between;
		color: var(--color---dark);
		padding: 0;
		margin: 20pt 0 10pt 0;
		> a {
			font-weight: bold;
			color: var(--color---folly);
		}
		> span {
			font-weight: bold;
			opacity: 0.5;
		}
	}
	> h3 {
		padding: 10pt 0 !important;
		margin: 10pt 0;
	}
	> .sb-event-view---container---website {
		display: flex;
		justify-content: center;
		height: 10pt;
		> a {
			display: flex;
			justify-content: center;
			gap: 10pt;
			height: 10pt;
			line-height: 10pt;
			color: var(--color---folly);
			font-size: 10pt;
			font-weight: bold;
		}
	}





}