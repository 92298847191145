.sb-events-category-view---container {
	> section {
		&.sb-events-category-view---category-image {
			margin: 0;
			height: 200pt;
		}
		&.sb-events-category-view---category-box {
			position: sticky;
			top: 0;
			z-index: 932;
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 100vw;
			height: auto;
			gap: 10pt;
			margin: var(--padding---global---page) calc(var(--padding---global---page) * -1);
			margin-top: 0 !important;
			padding: var(--padding---global---page) calc(var(--padding---global---page) * 2);
			background-color: var(--color---light);
			> p {
				display: block;
				width: 100%;
				height: auto;
				padding: 0;
				margin: 0;
				font-size: 10pt;
				font-weight: bold;
				line-height: 12pt;
				padding: calc(var(--padding---global---page) / 2);
				color: var(--color---dark);
				margin: 0;
				opacity: 0.5;
				text-align: center;
			}
			> h3 {
				display: block;
				height: auto;
				text-align: center;
				margin: 0;
			}
			&:after {
				content: '';
				position: absolute;
				z-index: 1923;
				top: 100%;
				right: 0;
				left: 0;
				display: block;
				width: 100vw;
				height: calc(var(--padding---global---page) * 4);
				background: linear-gradient(0deg,transparent 0%, var(--color---light) 90%);
				color: transparent;
			}
		}
		&.sb-events-category-view---category-scroller {
			display: block;
			height: auto;
			padding-top: calc(var(--padding---global---page) * 3);
			padding-bottom: calc(var(--padding---global---page) * 3);
		}
	}
}