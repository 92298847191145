.com-poi-disclaimer-acceptance---component {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 10pt;
	padding: calc(var(--padding---global---page) * 2) var(--padding---global---page);
	font-size: 10pt;
	font-weight: bold;
	> h6 {
		text-align: center;
		font-weight: bold;
		padding: 0;
		margin: 0;
	}
	> span {
		display: block;
		text-align: center;
		&:nth-of-type(2) {
			margin-top: 5pt;
			font-size: 8pt;
			opacity: 0.5;
		}
	}
}