.md-digitalmemu-dish-view---container {
	display: block;
	> .com-gallery-placeholder---component {
		height: 200pt;
	}
	> h4 {
		display: block;
		text-align: center;
		font-size: 16pt;
	}
	> p {
		display: block;
		text-align: center;
		font-size: 12pt;
	}
	> h5 {
		display: block;
		text-align: center;
		> span {
			display: inline-block;
			color: var(--color---folly);
			&:nth-child(1) {
				font-size: 16pt;
				font-weight: bold;
			}
			&:nth-child(2) {
				padding-left: 7.5pt;
				font-size: 12pt;
			}
		}
	}
	> h6 {
		display: block;
		text-align: center;
		opacity: 0.5;
		font-size: 10pt;
	}
	> .md-digitalmemu-dish-view---allergens {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 10pt;
		padding: 10pt;
		> div {
			width: 20pt;
			height: 20pt;
		}
	}
	> a {
		display: block;
		text-align: center;
		font-size: 10pt;
		color: var(--color---folly);
		> span {
			font-weight: bold;
			font-size: 10pt;
		}
		> i {
			font-size: 10pt;
			padding-left: 10pt;
		}
	}
}